export function validateForms(formRefs) {
  let objectList = [];
  let results = formRefs.map(
    (formRef) =>
    {
      if(formRef){
        return new Promise((resolve, reject) => {
          formRef.validate((valid, object) => {
            if (valid) {
              resolve();
            } else {
              objectList.push(object);
              reject();
            }
          });
        })
      }
    }
  );
  return Promise.all(results).catch(() => {
    return Promise.reject(objectList);
  });
}
