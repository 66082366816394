<template>
  <!-- 页面name：查看出险 -->
  <div class="contain">
    <el-page-header
      @back="goBack"
      :content="newAdd == 1?'新增出险':newAdd == 2?'结案':'查看出险'"
    >
      <template slot="title">
          <i class="el-icon-arrow-left"></i>返回
      </template>
    </el-page-header>
    <div class="form">
      <!-- <div class="or-case-title">是否结案:</div> -->
      <!-- <div class="or-case-con"> -->
      <el-form
        :disabled="ruleFormStatusAn"
        ref="ruleFormAn"
        :model="ruleFormAn"
        :rules="anRules"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="是否结案:" prop="isClosed" ref="isClosed">
          <el-radio-group v-model="ruleFormAn.isClosed">
            <el-radio
              v-for="(item, index) in theCaseList"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <!-- </div> -->
    </div>
    <div class="form">
      <div class="form-small-title">出险报案</div>

      <el-form
        :disabled="ruleFormStatus"
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="分公司:" prop="orgName" ref="orgName">
          <el-select
            v-model="ruleForm.orgName"
            placeholder="请选择分公司"
            :disabled="schoolListStatus == 1 ? true : ''"
          >
            <el-option
              v-for="item in moduleCompanyOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="园校:" prop="parkName" ref="parkName">
          <el-select
            v-model="ruleForm.parkName"
            placeholder="请选择园校"
            :disabled="schoolListStatus == 1 ? true : ''"
          >
            <el-option
              v-for="item in moduleParkOptions"
              :key="item.label"
              :label="item.name"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="typeE == 1" label="年龄段:" prop="generation" ref="generation">
          <el-select
            v-model="ruleForm.generation"
            clearable
            placeholder="请选择年龄段"
            class="select-condition"
          >
            <el-option
              v-for="item in dictList.generation_level"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出险人:" prop="insuredPerson" ref="insuredPerson">
          <el-input
            v-model.trim="ruleForm.insuredPerson"
            class="elInput"
            maxlength="50"
            placeholder="请输入出险人"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="伤害类别:" prop="injuryType" ref="injuryType">
          <el-select v-model="ruleForm.injuryType" placeholder="请选择伤害类别">
            <el-option
              v-for="item in dictList.injury_type"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件地点:" prop="accidentLocation" ref="accidentLocation">
          <el-select
            v-model="ruleForm.accidentLocation"
            placeholder="请选择事件地点"
          >
            <el-option
              v-for="item in dictList.accident_location"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="环节:" prop="link" ref="link">
          <el-select v-model="ruleForm.link" placeholder="请选择环节">
            <el-option
              v-for="item in dictList.danger_link"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="事件日期:" prop="accidentTime" ref="accidentTime">
          <el-date-picker
            v-model="ruleForm.accidentTime"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="请选择事件日期"
            style="width: 100%"
            :picker-options="pickerOptions"
            @change="handleDateChange"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="星期:" prop="week" ref="week">
          <el-select
            v-model="ruleForm.week"
            placeholder="请选择星期"
            :disabled="true"
          >
            <el-option
              v-for="item in dictList.danger_week"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="主责:" prop="mainResponsibility" ref="mainResponsibility">
          <el-radio-group v-model="ruleForm.mainResponsibility">
            <el-radio
              v-for="(item, index) in dictList.main_responsibility"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="事件经过:" prop="accidentCause" ref="accidentCause">
          <el-input
            v-model.trim="ruleForm.accidentCause"
            type="textarea"
            class="elInput"
            placeholder="请输入事件经过"
            clearable
            maxlength="1000"
          ></el-input>
        </el-form-item>
        <el-form-item label="伤害程度:" prop="degreeInjury" ref="degreeInjury">
          <el-input
            v-model.trim="ruleForm.degreeInjury"
            type="textarea"
            class="elInput"
            placeholder="请输入伤害程度"
            clearable
            maxlength="1000"
          ></el-input>
        </el-form-item>
        <el-form-item label="医疗费用:" prop="medicalExpenses" ref="medicalExpenses">
          <el-input
            v-model.trim="ruleForm.medicalExpenses"
            class="elInput"
            placeholder="请输入医疗费用"
            clearable
            maxlength="20"
            @input="limitInput($event, 'medicalExpenses')"
            @change="handleInput($event, 'medicalExpenses')"
          ></el-input>
          <span style="position:absolute;margin-left:undefined">元</span>
        </el-form-item>
      </el-form>
    </div>
    <div class="form" v-if="ruleFormAn.isClosed == '1'">
      <div class="form-small-title">出险结案</div>
      <el-form
        :disabled="outFormStatus"
        ref="outForm"
        :model="outForm"
        :rules="outRules"
        label-position="right"
        :label-width="labelWidth"
        class="demo-ruleForm"
      >
        <el-form-item label="是否已理赔:" prop="isClaimSettlement" ref="isClaimSettlement">
          <el-radio-group v-model="outForm.isClaimSettlement">
            <el-radio
              v-for="(item, index) in theCaseList"
              :key="index"
              :label="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <div v-if="outForm.isClaimSettlement == 1">
          <el-form-item label="理赔时间:" prop="claimTime" ref="claimTime">
            <el-date-picker
              v-model="outForm.claimTime"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="请选择理赔时间"
              style="width: 100%"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>

          <el-form-item label="理赔金额:" prop="claimAmount" ref="claimAmount">
            <el-input
              v-model.trim="outForm.claimAmount"
              class="elInput"
              placeholder="请输入理赔金额"
              clearable
              maxlength="20"
              @input="limitInput($event, 'claimAmount')"
              @change="handleInput($event, 'claimAmount')"
            ></el-input>
            <span style="position:absolute;margin-left:undefined">元</span>
          </el-form-item>

          <el-form-item label="是否有额外赔偿:" prop="isAdditionalCompensation" ref="isAdditionalCompensation">
            <el-radio-group v-model="outForm.isAdditionalCompensation">
              <el-radio
                v-for="(item, index) in theCaseList"
                :key="index"
                :label="item.value"
              >
                {{ item.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <div v-if="outForm.isAdditionalCompensation == 1">
            <el-form-item label="额外赔偿金额:" prop="additionalClaimAmount" ref="additionalClaimAmount">
              <el-input
                v-model.trim="outForm.additionalClaimAmount"
                class="elInput"
                placeholder="如无额外赔偿金额，请写“0”"
                clearable
                maxlength="20"
                @input="limitInput($event, 'additionalClaimAmount')"
                @change="handleInput($event, 'additionalClaimAmount')"
              ></el-input>
              <span style="position:absolute;margin-left:undefined">元</span>
            </el-form-item>

            <el-form-item label="其他赔偿:" prop="otherCompensation" ref="otherCompensation">
              <el-input
                v-model="outForm.otherCompensation"
                type="textarea"
                class="elInput"
                placeholder="如无其他赔偿，请写“无”"
                clearable
                maxlength="500"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <el-form-item label="备注:" prop="remarks" ref="remarks">
          <el-input
            v-model.trim="outForm.remarks"
            type="textarea"
            class="elInput"
            placeholder="请输入备注"
            clearable
            maxlength="500"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="form-btn">
      <el-button class="cancel" @click="onCancel">返回</el-button>
      <el-button
        v-if="newAdd == 1 || newAdd == 2"
        type="primary"
        class="submit"
        @click="onSubmit()"
      >
        提交
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import downloadFile from "@/utils/downloadFile";
import { validateForms } from "@/utils/vliforms";
export default {
  data() {
    return {
      ruleFormAn: {
        isClosed: "",
      },
      anRules: {
        isClosed: [
          { required: true, message: "请选择是否结案", trigger: "blur" },
        ],
      },
      ruleForm: {
        orgId: "", //分公司
        parkId: "", //园校
        orgName: "",
        parkName: "",
        generation: "", //年龄段
        insuredPerson: "", //出险人
        injuryType: "", //伤害类别
        accidentLocation: "", //事件地点
        link: "", //环节
        accidentTime: "", //事件日期
        week: "", //星期
        mainResponsibility: "", //主责
        accidentCause: "", //事件经过
        degreeInjury: "", //伤害程度
        medicalExpenses: "", //医疗费用
      },

      rules: {
        orgId: [{ required: true, message: "请选择公司", trigger: "blur" }],
        parkId: [{ required: true, message: "请选择园校", trigger: "blur" }],
        generation: [
          { required: true, message: "请选择年龄段", trigger: "blur" },
        ],
        insuredPerson: [
          { required: true, message: "请选择输入出险人", trigger: "blur" },
        ],
        injuryType: [
          { required: true, message: "请选择伤害类别", trigger: "blur" },
        ],
        accidentLocation: [
          { required: true, message: "请选择事件地点", trigger: "blur" },
        ],
        link: [{ required: true, message: "请选择环节", trigger: "blur" }],
        accidentTime: [
          { required: true, message: "请选择事件日期", trigger: "blur" },
        ],
        week: [{ required: true, message: "请选择星期", trigger: "blur" }],
        mainResponsibility: [
          { required: true, message: "请选择主责", trigger: "blur" },
        ],
        accidentCause: [
          { required: true, message: "请输入事件经过", trigger: "blur" },
        ],
        degreeInjury: [
          { required: true, message: "请输入伤害程度", trigger: "blur" },
        ],
        medicalExpenses: [
          { required: true, message: "请输入医疗费用", trigger: "blur" },
        ],
        named: [{ required: true, message: "请输入", trigger: "blur" }],
        sex: [{ required: true, message: "请输入", trigger: "blur" }],
      },

      outForm: {
        isClaimSettlement: "",
        claimTime: "",
        claimAmount: "",
        isAdditionalCompensation: "",
        additionalClaimAmount: "",
        otherCompensation: "",
        remarks: "",
      },

      outRules: {
        isClaimSettlement: [
          { required: true, message: "请选择是否已理赔", trigger: "blur" },
        ],
        claimTime: [
          { required: true, message: "请选择理赔时间", trigger: "blur" },
        ],
        claimAmount: [
          { required: true, message: "请输入理赔金额", trigger: "blur" },
        ],
        isAdditionalCompensation: [
          { required: true, message: "请选择是否有额外赔偿", trigger: "blur" },
        ],
        additionalClaimAmount: [
          { required: true, message: "请输入额外赔偿金额", trigger: "blur" },
        ],
        otherCompensation: [
          { required: true, message: "请输入其他赔偿", trigger: "blur" },
        ],
      },
      // isClosed: "",
      type: "",
      moduleCompanyOptions: [], //所属公司下拉
      moduleParkOptions: [], //所属园校下拉
      theCaseList: [
        { value: "1", name: "是" },
        { value: "0", name: "否" },
      ],
      mainResponList: [
        { value: "1", name: "家长" },
        { value: "2", name: "幼儿" },
        { value: "3", name: "员工" },
        { value: "0", name: "无" },
      ],
      lookId: "",
      newAdd: "",
      typeE: "",
      dictList: {
        generation_level: [],
        danger_week: [],
        danger_link: [],
        accident_location: [],
        injury_type: [],
        main_responsibility:[],
      },
      ruleFormStatusAn: false,
      ruleFormStatus: false,
      outFormStatus: false,
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },
      schoolListStatus: 0,
      metaTitle:'',

      labelWidth: '150px',
    };
  },

  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },

  mounted() {
    this.lookId = this.$route.query.lookId;
    this.newAdd = this.$route.query.newAdd;
    this.typeE = this.$route.query.typeE;

    if (this.$route.query.lookId && this.$route.query.newAdd == 0) {
      this.getPayGetById();
      this.ruleFormStatus = true;
      this.outFormStatus = true;
      this.ruleFormStatusAn = true;
    } else if (this.$route.query.lookId && this.$route.query.newAdd == 2) {
      this.getPayGetById();
      this.ruleFormStatus = true;
      this.ruleFormStatusAn = false;
      // this.ruleFormAn.isClosed = 1;
    }

    if (this.userInfo.schoolList.length > 0) {
      this.ruleForm.orgId = this.userInfo.schoolList[0].companyLabel; //分公司
      this.ruleForm.parkId = this.userInfo.schoolList[0].schoolLabel; //园校
      this.ruleForm.orgName = this.userInfo.schoolList[0].companyName; //分公司
      this.ruleForm.parkName = this.userInfo.schoolList[0].schoolName; //园校

      this.schoolListStatus = 1;
    }
    if(this.newAdd == 1){
      this.metaTitle = '新增出险'
    }else if(this.newAdd == 2){
      this.metaTitle = '结案'
      this.ruleFormAn.isClosed = '1'
    }else{
      this.metaTitle = '查看出险'
    }
    let chooseMenuInfoList = sessionStorage.getItem("chooseMenuInfoList") ? JSON.parse(sessionStorage.getItem("chooseMenuInfoList")) : null
    if(chooseMenuInfoList){
      chooseMenuInfoList.map((item) => {
      if(item.path == this.$route.path){
          item.meta.title = this.metaTitle
      }
      })
      let breadCrumbList = JSON.parse(JSON.stringify(chooseMenuInfoList)) 
      breadCrumbList.shift()
      // console.log(breadCrumbList)
      this.$store.commit("common/getBreadcrumbList",breadCrumbList || [])
    }

    window.addEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '110px'
      } else {
        this.labelWidth = (150 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
    if(window.innerWidth < 1300){
      this.labelWidth = '110px'
    } else {
      this.labelWidth = (150 / 1920 * window.innerWidth) + 'px'
    }

    this.queryDict();
    this.getCompanyDeptList();
    this.getSchoolList();
    this.getClassInfoList();
  },

  methods: {
    // getDicList(){
    //   let params = {
    //     type: "main_responsibility",
    //   };
    //   this.$api.getDictionary(params).then((res) => {
    //     if (res.data.code == 0) {
    //       this.contagionLevelList = res.data.data;
    //     } else {
    //       this.$message.error(res.data.msg);
    //     }
    //   });
    // },
    //保留两位小数校验
    limitInput(value, name) {
      if (name == "medicalExpenses") {
        this.ruleForm[name] =
          ("" + value) // 第一步：转成字符串
            .replace(/[^\d^\.]+/g, "") // 第二步：把不是数字，不是小数点的过滤掉
            .replace(/^0+(\d)/, "$1") // 第三步：第一位0开头，0后面为数字，则过滤掉，取后面的数字
            .replace(/^\./, "0.") // 第四步：如果输入的第一位为小数点，则替换成 0. 实现自动补全
            .match(/^\d*(\.?\d{0,2})/g)[0] || ""; // 第五步：最终匹配得到结果 以数字开头，只有一个小数点，而且小数点后面只能有0到2位小数
      } else if (name == "claimAmount") {
        this.outForm[name] =
          ("" + value)
            .replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      } else if (name == "additionalClaimAmount") {
        this.outForm[name] =
          ("" + value)
            .replace(/[^\d^\.]+/g, "")
            .replace(/^0+(\d)/, "$1")
            .replace(/^\./, "0.")
            .match(/^\d*(\.?\d{0,2})/g)[0] || "";
      }

      const iNum = 20;
      //获取输入的文字的长度
      const textLength = value.length;

      // if (i - textLength >= 0) {
      //   var divcontent = document.getElementById("num").innerText;
      //   document.getElementById("num").innerText =
      //     "还可以输入" + (i - textLength) + "个字";
      // }

      if (textLength > 16) {
        // console.log(new String(value.substr(0, 16)), "apei");
        // new 字符串对象
        // this.outForm[name] = new String(value.substr(0, 16));
        if (name == "medicalExpenses") {
          this.ruleForm[name] = new String(value.substr(0, 16));
        } else if (name == "claimAmount") {
          this.outForm[name] = new String(value.substr(0, 16));
        } else if (name == "additionalClaimAmount") {
          this.outForm[name] = new String(value.substr(0, 16));
        }

        // // this.$forceUpdate()
        // console.log(
        //   "当满足条件时被我修改的value(绑定的vModel)的值",
        //   this.ruleForm[name],
        //   this.outForm[name]
        // );
      }
      // this.$nextTick(() => {
      //   this.outForm[name] = this.outForm[name].toString();
      //   this.ruleForm[name] = this.ruleForm[name].toString();
      // });
      // if (iNum - textLength <= 3) {
      //   // console.log("不可输入");
      //   this.handleInput(value, name);
      //   return false;
      // }
    },

    //为整数字符串在末尾添加.00,为小数添加.0
    handleInput(val, type) {
      // return false;
      val = val.replace(/[^\w\.\/]/ig,'')
      if (val != "") {
        // var value = Math.round(parseFloat(val) * 100) / 100;
        var value = val;
        var s = val.toString().split(".");

        if (s.length <= 1) {
          if (type == "medicalExpenses") {
            this.ruleForm.medicalExpenses = value.toString() + ".00";
          } else if (type == "claimAmount") {
            this.outForm.claimAmount = value.toString() + ".00";
          } else if (type == "additionalClaimAmount") {
            this.outForm.additionalClaimAmount = value.toString() + ".00";
          }
        }

        if (s.length > 1) {
          if (s[1] == "" || s[1].length < 1) {
            if (type == "medicalExpenses") {
              this.ruleForm.medicalExpenses = value.toString() + "00";
            } else if (type == "claimAmount") {
              this.outForm.claimAmount = value.toString() + "00";
            } else if (type == "additionalClaimAmount") {
              this.outForm.additionalClaimAmount = value.toString() + "00";
            }
          }
          if (s[1].length < 2 && s[1].length > 0) {
            if (type == "medicalExpenses") {
              this.ruleForm.medicalExpenses = value.toString() + "0";
            } else if (type == "claimAmount") {
              this.outForm.claimAmount = value.toString() + "0";
            } else if (type == "additionalClaimAmount") {
              this.outForm.additionalClaimAmount = value.toString() + "0";
            }
          }
        }
      }
      // if (!/\./.test(val)) {
      //   this.ruleForm.medicalExpenses = val += ".00";
      // }
      //为整数字符串在末尾添加.00
    },

    //转为中国标准时间
    formatterDate(date) {
      let result = new Date(date);
      return result;
    },

    //监听点击日期对应周几
    handleDateChange() {
      let todate = this.formatterDate(this.ruleForm.accidentTime);
      let toweek = todate.getDay();
      if (toweek == 0) {
        this.ruleForm.week = "7";
      } else {
        this.ruleForm.week = String(toweek);
      }
    },

    //字典查询
    queryDict() {
      var dict = Object.keys(this.dictList);
      let requests = dict.map(
        (item) =>
          new Promise((resolve, reject) => {
            this.$api
              .getDictionary({ type: item })
              .then((res) => {
                res.data.fieldName = item;
                resolve(res.data);
              })
              .catch(() => {
                resolve([]);
              });
          })
      );
      Promise.all(requests).then((res) => {
        res.forEach((item) => {
          this.dictList[item.fieldName] = item.data;
        });
      });
    },

    //查询分公司
    getCompanyDeptList() {
      let params = {
        type: "NODE_ORG",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleCompanyOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询园校
    getSchoolList(valCompany) {
      let params = {
        parent: valCompany,
        type: "NODE_SCHOOL",
      };
      this.$api.getCompanyDept(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleParkOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询分公司change事件
    // handleCompany(val) {
    //   if (val) {
    //     this.disabledPark = false;
    //     this.getSchoolList(val);
    //   }
    // },

    //查询园校change事件
    // handlePark(val) {
    //   if (val) {
    //     this.disabledClass = false;
    //     this.getClassInfoList(val);
    //   }
    // },

    //查询班级
    getClassInfoList(valPark) {
      let params = {
        parentLabel: valPark,
      };
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleClassOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查看
    getPayGetById() {
      let params = {
        id: this.lookId,
      };
      this.$api.getPayGetById(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == 0) {
          this.ruleForm = res.data.data;
          this.outForm = res.data.data;
          this.ruleFormAn = res.data.data;
          console.log(this.ruleFormAn)
          if(this.newAdd == 2){
            this.ruleFormAn.isClosed = '1'
          }
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    /** 导出 */
    downLoadFile() {
      //下载文件
      let url = "/mng/insurance/pay/exportList";

      let data = {
        type: "1",
        orgId: this.ruleForm.orgId,
        parkId: this.ruleForm.parkId,
        policyholder: this.ruleForm.policyholder,
        injuryType: this.ruleForm.injuryType,
        week: this.ruleForm.week,
        accidentLocation: this.ruleForm.accidentLocation,
        link: this.ruleForm.link,
        isClosed: this.ruleFormAn.isClosed,
        beginAmount: this.ruleForm.beginAmount,
        endAmount: this.ruleForm.endAmount,
        beginTime: this.ruleForm.accidentTime[0],
        endTime: this.ruleForm.accidentTime[1],
      };

      let type = "get";

      this.tableLoading = true;
      downloadFile({ url, data, type }).then(() => {
        this.tableLoading = false;
      });
    },

    //返回上一页
    goBack() {
      this.$router.go(-1);
    },

    //取消
    onCancel() {
      this.goBack();
    },

    //提交
    onSubmit() {
      let formRefs = ["ruleFormAn", "ruleForm", "outForm"].map(
        (key) => this.$refs[key]
      );

      let obj1 = this.ruleForm;
      let obj2 = this.outForm;
      let obj3 = this.ruleFormAn;
      let obj = Object.assign(obj1, obj2, obj3);
      // obj.isClosed = this.isClosed
      obj.type = this.typeE;
      if (this.newAdd == 1) {
        validateForms(formRefs)
          .then(() => {
            this.$api.getPaySave(obj).then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.data);
                this.goBack();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            for (let i in err[0]) {
              let dom = this.$refs[i]
              dom.$el.scrollIntoView({
                //滚动到指定节点
                block: 'center', //值有start,center,end，nearest，当前显示在视图区域中间
                behavior: 'smooth' //值有auto、instant,smooth，缓动动画（当前是慢速的）
              })
              break
            }
          });
      } else if (this.newAdd == 2) {
        validateForms(formRefs)
          .then(() => {
            this.$api.getPayUpdate(obj).then((res) => {
              if (res.data.code == 0) {
                this.$message.success(res.data.data);
                this.goBack();
              } else {
                this.$message.error(res.data.msg);
              }
            });
          })
          .catch((err) => {
            console.log(err);
            for (let i in err[0]) {
              let dom = this.$refs[i]
              dom.$el.scrollIntoView({
                //滚动到指定节点
                block: 'center', //值有start,center,end，nearest，当前显示在视图区域中间
                behavior: 'smooth' //值有auto、instant,smooth，缓动动画（当前是慢速的）
              })
              break
            }
          });
      }
    },
  },
  beforeDestroy(){
    window.removeEventListener("resize",(e) => {
      if(e.target.innerWidth < 1300){
        this.labelWidth = '110px'
      } else {
        this.labelWidth = (150 / 1920 * e.target.innerWidth) + 'px'
      }
      this.$forceUpdate()
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

::v-deep .el-upload {
  width: 192px;
  height: 108px;
  line-height: 108px;
}
.contain {
  padding: 34px 30px 30px 30px;
  /deep/.el-radio__input.is-checked .el-radio__inner {
    border-color: #003685;
    background: #003685;
  }

  /deep/.el-radio__input.is-checked + .el-radio__label {
    color: #003685;
  }

  /deep/.el-radio {
    font-size: 14px;
    font-weight: 400;
    color: #010334;
  }

  .or-case {
    display: flex;
    align-items: center;
    margin-top: 30px;

    .or-case-title {
      margin-right: 30px;
      font-size: 16px;
      font-weight: 400;
      color: #606266;
    }
    .or-case-con {
    }
  }

  .form {
    width: 80%;
    // min-height: 696px;
    margin-top: 20px;

    .form-small-title {
      font-size: 18px;
      font-weight: 400;
      margin: 10px 0 20px 0;
    }

    /deep/.el-form-item .el-select {
      width: 100%;
      height: 40px;
    }

    /deep/.el-form-item__label {
      font-size: 16px;
      font-weight: 400;
      color: #606266;
      padding: 0 20px 0 0;
    }
    /deep/.el-input--suffix .el-input__inner {
      padding-right: 30px;
    }
    /deep/.el-input--suffix .el-input__inner,
    /deep/.el-textarea__inner {
      font-size: 14px;
      font-weight: 400;
      color: #010334;
    }

    /deep/.el-textarea__inner {
      height: 80px;
    }
  }

  .form-btn {
    margin-top: 43px;
    display: flex;
    padding-left: 100px;

    .cancel {
      width: 137px;
      height: 40px;
      border: 1px solid #b5bdcf;
      border-radius: 4px;
      font-size: 14px;
      line-height: 14px;
      color: #222222;
      opacity: 1;
    }

    .submit {
      display: block;
      width: 137px;
      height: 40px;
      background: #2a00b2;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }
}
</style>
